import React from 'react';
import {Route, Routes} from "react-router-dom";
import {IndexPage} from "./pages/index/IndexPage";

function App() {
  return (
      <>
        <Routes>
          <Route path="/">
            <Route index element={ <IndexPage />} />                                {/* DONE */}
            {/*<Route path="login" element={ <LoginPage />} />                         /!* DONE *!/*/}
            {/*<Route path="register" element={ <RegisterPage />} />                   /!* DONE *!/*/}

            {/*<Route path="calendar/:id" element={ <CalendarPage />} />*/}

            {/*<Route path="users/:id" element={ <UserPage />} />                      /!* DONE *!/*/}
            {/*<Route path="subscriptions" element={ <SubscriptionsPage />} />         /!* DONE *!/*/}
            {/*<Route path="observers" element={ <ObserversPage />} />                 /!* DONE *!/*/}

            {/*<Route path="subscriptions/add" element={ <SubscriptionsAddPage />} />  /!* DONE *!/*/}
            {/*<Route path="subscriptions/out" element={ <SubscriptionsOutPage />} />  /!* DONE *!/*/}
            {/*<Route path="observers/in" element={ <ObserversInPage />} />            /!* DONE *!/*/}

            {/*<Route path="day" element={ <DayPage />} />                             /!* DONE *!/*/}

            {/*<Route path="*" element={ <NotFoundPage />} />                          /!* DONE *!/*/}
          </Route>
        </Routes>
      </>
  )
}

export default App;
