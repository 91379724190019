import styles from './IndexPage.module.scss'
import {CardComponent} from "../../components/card/CardComponent";

export function IndexPage() {
    return (
        <div className={styles.page}>
            <div className={styles.flagBox}>
                <div className={styles.flagBoxLeft}></div>
                <div className={styles.flagBoxCenter}></div>
                <div className={styles.flagBoxRight}></div>
            </div>

            <p className={styles.h1}>Проекты</p>
            <p className={styles.h2}>LGleba</p>

            <CardComponent
                title={"1. Red Days"}
                description={"Наш сервис для отслеживания менструального цикла предоставляет удобное и надежное решение для женщин, желающих контролировать и понимать свой физиологический цикл. Наша цель - помочь женщинам лучше понимать свое тело, предоставляя им инструменты для ведения дневника менструального цикла, прогнозирования периодов овуляции и общего здоровья."}
                link={"https://red-days.ru"}
            />

            <CardComponent
                title={"2. Кредитный калькулятор"}
                description={"Наш сервис для подсчета кредитных условий предоставляет удобный и надежный инструмент для расчета и оценки кредитных параметров. Наша цель - помочь вам принимать информированные финансовые решения, предоставляя точные данные о кредитных условиях и расчетах."}
                link={"https://lgleba.ru/credit"}
            />

            <CardComponent
                title={"3. Cocomo"}
                description={"Наш сервис для COCOMO (Constructive Cost Model) предоставляет инструмент для оценки и управления затратами и временем разработки программного обеспечения. COCOMO - это широкоизвестная модель оценки затрат, разработанная Барри Боэмом в 1981 году, и наш сервис использует эту модель для предоставления точных и надежных прогнозов затрат на разработку проектов."}
                link={"https://lgleba.ru/cocomo"}
            />

            <div className={styles.flagBox}>
                <div className={styles.flagBoxLeft}></div>
                <div className={styles.flagBoxCenter}></div>
                <div className={styles.flagBoxRight}></div>
            </div>
        </div>
    )
}