import styles from './CardComponent.module.scss'

import imgGlobal from '../../img/svg/global.svg'
import imgRowRight from '../../img/svg/rowRight.svg'
import {Link} from "react-router-dom";

interface CardComponentProps {
    title: string
    description: string
    link: string
}

export function CardComponent(props: CardComponentProps) {
    return (
        <div className={styles.box}>
            <p className={styles.boxTitle}>{props.title}</p>

            <p className={styles.boxDescription}>{props.description}</p>

            <Link to={props.link} target="_blank" className={styles.boxButton}>
                <img className={styles.boxButtonLeft} src={imgGlobal} alt="Интернет" loading="lazy"/>
                <p className={styles.boxButtonCenter}>{props.link}</p>
                <img className={styles.boxButtonRight} src={imgRowRight} alt="Открыть" loading="lazy"/>
            </Link>
        </div>
    )
}
